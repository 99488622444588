
  import {createControllersWithDescriptors, initAppForPageWrapper} from '@wix/yoshi-flow-editor/runtime/esm/viewerScript.js';
  
  

  
  var importedApp = {};


  

    var velocycleMobx = null;
    


  

    var blocksControllerService = null;
    

  
import { createHttpClient } from '@wix/yoshi-flow-editor/runtime/esm/controller/httpClientProp';
    

  
var initI18n = null;

  

    var createExperiments = null;
    var createWidgetExperiments = null;
    


  var sentryConfig = {
      DSN: 'https://820708d4ac8f4ec69ed1f59f1bb3b5e4@sentry.wixpress.com/617',
      id: '820708d4ac8f4ec69ed1f59f1bb3b5e4',
      projectName: 'reviews-ooi-test-app',
      teamName: 'wix-comments',
      
    };

  var experimentsConfig = null;

  var translationsConfig = {"enabled":false,"icuEnabled":false};

  var defaultTranslations = null;

  var fedopsConfig = null;

  import { createVisitorBILogger as biLogger } from '/home/builduser/work/189599a6bfa34d79/reviews-web/reviews-ooi-test-app/target/generated/bi/createBILogger.ts';

  export const exports = importedApp.exports;

  export const initAppForPage = initAppForPageWrapper({
    initAppForPage: importedApp.initAppForPage,
    sentryConfig: sentryConfig,
    experimentsConfig: experimentsConfig,
    inEditor: false,
    biLogger: biLogger,
    projectName: "reviews-ooi-test-app",
    biConfig: {"owner":"@wix/bi-logger-editor-flow-template","visitor":"@wix/bi-logger-editor-flow-template","enableUniversalEvents":false},
    appName: "reviews-ooi-test-app",
    appDefinitionId: "2f406717-2cb4-4ce6-bc01-48ac7f72660b",
    fedopsConfig: fedopsConfig,
    translationsConfig: translationsConfig,
    defaultTranslations: defaultTranslations,
    shouldUseEssentials: false,
    optionalDeps: {
      initI18n,
      createHttpClient,
      createExperiments,
    },
    localeDistPath: "assets/locales",
  });

  const _createControllers = createControllersWithDescriptors({
    initI18n,
    blocksControllerService,
    createHttpClient,
    createExperiments,
    velocycleMobx,
  }, [{ method: null,
          wrap: null,
          exports: null,
          widgetType: "WIDGET_OUT_OF_IFRAME",
          translationsConfig: translationsConfig,
          experimentsConfig: null,
          fedopsConfig: fedopsConfig,
          sentryConfig: sentryConfig,
          persistentAcrossPages: false,
          biLogger: biLogger,
          shouldUseEssentials: false,
          withErrorBoundary: false,
          biConfig: {"owner":"@wix/bi-logger-editor-flow-template","visitor":"@wix/bi-logger-editor-flow-template","enableUniversalEvents":false},
          controllerFileName: "/home/builduser/work/189599a6bfa34d79/reviews-web/reviews-ooi-test-app/src/components/Reviews/controller.ts",
          appName: "reviews-ooi-test-app",
          appDefinitionId: "2f406717-2cb4-4ce6-bc01-48ac7f72660b",
          projectName: "reviews-ooi-test-app",
          componentName: "Reviews",
          localeDistPath: "assets/locales",
          defaultTranslations: defaultTranslations,
          id: "3dfd9303-8b9e-4607-9cae-13f257024c1d" }],
    false);

    export const createControllers = _createControllers
